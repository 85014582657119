import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "jargon"
    }}>{`Jargon`}</h1>
    <p>{`Avoid any technical language and unfamiliar words. Our Workday voice favors being clear and
approachable to all types of users, so use simple, commonly-understood language.`}</p>
    <h2 {...{
      "id": "unfamiliar-words"
    }}>{`Unfamiliar Words`}</h2>
    <p>{`When defining or introducing a word or phrase in a sentence, put it in quotation marks on the first
reference only. (Foreign words should be in italic.)`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`The browser will accept a “cookie” and open a new window. A cookie is a small amount of data from
a website that is stored on your computer.`}</li>
      </ul>
    </Do>
    <p>{`See also: `}<a parentName="p" {...{
        "href": "/guidelines/content/language-and-style/product-references"
      }}>{`Product References`}</a>{`,
`}<a parentName="p" {...{
        "href": "/guidelines/content/language-and-style/acronyms"
      }}>{`Abbreviations and Acronyms`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      